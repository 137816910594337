

/* .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
} */

.App-link {
  color: #61dafb;
}

.overdue{
  background-color: #ffa500;
}

.formContainer {
  padding: 1em
}
.inputContainer {
  padding: 0em
}

.textInput {
  margin: 1em
}
